import React, {useEffect} from "react"

/** framer-motion **/
import {motion} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {useAnimation} from "framer-motion";

const Solution = () => {

    const {ref, inView} = useInView({
        threshold: .5
    });
    const slideIn = useAnimation();
    const fadeIn = useAnimation();

    useEffect(() => {
        if (inView) {
            slideIn.start({
                opacity: 1,
                x: 0,
                transition: {
                    delay: 0,
                    type: "spring",
                    duration: 1.9,
                    bounce: 0.3,
                },
            });
            fadeIn.start({
                opacity: 1,
                y: 0,
                transition: {
                    delay: 0,
                    type: "spring",
                    duration: 3,
                    bounce: 0.3,
                },
            });
        } else {
            slideIn.set({
                x: -300,
                opacity: 0,
            });

            fadeIn.set({
                y: -40,
                opacity: 0,
            });
            slideIn.start({
                opacity: 0,
                x: -300,
                transition: {
                    type: "spring",
                    duration: 1,
                    bounce: 0.3,
                },
            });
            fadeIn.start({
                opacity: 0,
                y: 60,
                transition: {
                    type: "spring",
                    duration: 1,
                    bounce: 0.3,
                },
            });
        }
    }, [inView])


    return (
        <div ref={ref} className="home-solves d-flex flex-column align-items-start w-75">
            <motion.h1 animate={slideIn}>SOLUTIONS WE PROVIDE</motion.h1>
            <motion.div animate={fadeIn}>
                <p>With our Blockchain Land Registry Solution you can...</p>
                <ul>
                    <li>Manage all rented or owned Properties/Lands</li>
                    <li>Benefit from the decentralized security </li>
                    <li>Modernise the existing land registries</li>
                    <li>Automatically detect zoning frauds</li>
                    <li>Increase public accessibility to the land registry</li>
                    <li>Reduce possibilities of land grabbing and other types of corruption</li>
                </ul>
                <p>Our goal is to create an accessible, transparent, decentralized land registry</p>
            </motion.div>
        </div>
    )
}

export default Solution;
