import React from 'react'
import "./background.css"

export default function Background({ children }) {
    return (
        <div className="background-container">
            <div className="stars">
            <div className="twinkling">
            {children}

            </div>
            </div>
            
        </div>
    )
}
