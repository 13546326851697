import React, { useState } from 'react';
import './contact.css';
import emailjs from 'emailjs-com';
import {Alert} from "react-bootstrap"

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
const TEMPLATE_ID = process.env.REACT_APP_CONTACT_TEMPLATE_ID;

const Contact = () => {
  const [success, setSuccess] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const firstName = e.target.firstName.value;
    const lastName = e.target.lastName.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    const message = e.target.message.value;
  
    // Send the email using EmailJS 
    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, {
        to_email: 'info@swupelpms.com', // Destination email
        from_name: `${firstName} ${lastName}`,
        from_email: email,
        phone,
        message,
      }, PUBLIC_KEY)
        .then((response) => {
        console.log('Email sent successfully!', response);
        // Clear the form fields 
        e.target.reset();
        setSuccess(true);
        console.log(success)
      })
      .catch((error) => {
        console.error('Email could not be sent:', error);
        setSuccess(false);
        // Handle errors, e.g., display an error message to the user
      });
  };
  

  return (
    <div className="contact-container">
      <div className="contact-form">
        {success && <Alert variant="success" className="text-center" dismissible>Your message has been sent successfully</Alert>}
        <div className="form-header">Just say Hello !</div>
        <div className='form-subHeader'>Let us know more about you</div>
        <form onSubmit={handleSubmit}>
          <div className='contact-flex-container'>
            <input className="form-input" placeholder='First Name' type="text" id="firstName" name="firstName" required autoComplete='off'/>
            <input className="form-input" placeholder='Last Name' type="text" id="lastName" name="lastName" required autoComplete='off'/>
          </div>
          <div className='contact-flex-container'>
            <input className="form-input" placeholder='Mail' type="email" id="email" name="email" required autoComplete='off'/>
            <input className="form-input" placeholder='Phone' type="tel" id="phone" name="phone" required autoComplete='off'/>
          </div>
          <textarea className="form-input" placeholder='Message' id="message" name="message" rows="4" required autoComplete='off'></textarea>
          <button className="submit-btn" type="submit">Submit</button>
        </form>
      </div>
      <div className="contact-info">
        <div className='form-header'>Contact Information</div>
        <div><span style={{fontWeight:"bold"}}>Phone:</span> +43 676 5378928</div>
        <div><span style={{fontWeight:"bold"}}>Email:</span> <a href="mailto:info@swupelpms.com">info@swupelpms.com</a></div>
        <div><span style={{fontWeight:"bold"}}>Address:</span> Vienna, Austria</div>
      </div>
    </div>
  );
}

export default Contact;
