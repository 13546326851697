import React from "react";
import "./Team.css";
import MemberCard from "./MemberCard";

import teamMembers from "./team_members";

const Team = () => {
  return (
    <div className="team-container">
      <header>
        <h1>Meet Our Team</h1>
      </header>
      <div className="team-grid">
        {teamMembers.map((member) => {
          return (
            <MemberCard
              name={member.name}
              about={member.about}
              email={member.email}
              role={member.role}
              countryCode={member.country_iso_code}
              city={member.city}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Team;
