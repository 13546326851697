export const Navbar_links = [
  {
    title: "About",
    url: "about",
    cName: "nav-links",
  },
  {
    title: "Team",
    url: "team",
    cName: "nav-links",
  },
  {
    title: "Contact",
    url: "contact",
    cName: "nav-links",
  },
  {
    title: "Demo",
    url: "Demo",
    cName: "nav-links",
  },
];
