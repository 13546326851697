import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";

import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div class="footer-content">
        <div class="left-section">
          <p>&copy; 2024 Swupelpms. All rights reserved.</p>
        </div>
        <div class="right-section">
          <div class="social-links">
            <p className="footer-header">Join Us</p>
            <div className="socials">
              <a href="mailto:info@swupelpms.com">
                <GmailIcon fontSize="small" /> Email
              </a>
              <a
                href="https://www.linkedin.com/company/swupel-pms"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon fontSize="small" /> LinkedIn
              </a>
              <a
                href="https://twitter.com/swupelpms"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon fontSize="small" /> Twitter
              </a>
            </div>
          </div>
          <div className="company">
            <p className="footer-header">Company</p>
            <div className="company-links">
              <Link to={"/about"}>About</Link>
              <Link to={"/team"}>Team</Link>
              <Link to={"/careers"}>Careers</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
