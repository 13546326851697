import React, { useState } from 'react';

const ImageWithOverlay = ({ src, alt, imageStyle }) => {
   const [isImageClicked, setImageClicked] = useState(false);

   const handleImageClick = () => {
      setImageClicked(!isImageClicked);
   };

   // Prevent scrolling when image is expanded
   if (isImageClicked) {
      document.body.style.overflow = 'hidden';
   } else {
      document.body.style.overflow = 'auto';
   }

   const expandedClass = 
      imageStyle === 'image-style-2' ? 'expanded-2' : 'expanded';

   return (
      <>
         <img
            src={src}
            alt={alt}
            className={`${imageStyle} ${isImageClicked ? expandedClass : ''}`}
            onClick={handleImageClick}
         />
         {isImageClicked && (
            <div className="image-overlay" onClick={handleImageClick}>
               <img
                  src={src}
                  alt={alt}
                  className={`${imageStyle}-expanded-image`}
               />
            </div>
         )}
      </>
   );
};

export default ImageWithOverlay;
