import React from 'react'

import "./home.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import Title from "./components/Title";
import Problems from "./components/Problems";
import Solution from "./components/Solution";
import Demonstration from "./components/Demonstration";


export default function Home() {

    return (
        <section>
            <div className="home-container" >
                <Title />
                <Problems />
                <Solution />
                <Demonstration />
            </div>
        </section>
    )
}
