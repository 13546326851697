import React from "react";
import "./Careers.css";
import { Link } from "react-router-dom";

const Careers = () => {
  return (
    <main className="career-main-container">
      <div className="sub-container">
        <h1 className="career-heading">Contribution</h1>
        <div className="line" />
        <div className="content-container">
          <div className="contribution-areas">
            <p>
              Want to contribute? We are looking for help in any of the
              following areas:
            </p>
            <ul>
              <li>Machine Learning</li>
              <li>Blockchain</li>
              <li>Frontend / Backend</li>
            </ul>
          </div>
          <div className="contribution-description">
            <p>
              Swupel PMS is a startup, being built from the
              ground up by passionate engineers all around the world.
            </p>
            <p>
              But solving a problem of this magnitude requires an immense amount
              of resources, which is why we appreciate all of the help that we
              can get!
            </p>
            <p>
              If you are an engineer that would like to contribute in any of the
              core areas of our product, please do not hesitate to reach out to
              us, we look forward to working with you.
            </p>
            <p>
              {" "}
              You can reach out to us{" "}
              <span>
                <Link to={"/contact"}>here</Link>
              </span>{" "}
              or via email at <span>info@swupelpms.com</span>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Careers;
