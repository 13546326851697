import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator, Avatar } from '@chatscope/chat-ui-kit-react';
import './Chatbot.css'

function Chatbot({ messages, handleSend, isTyping }) {
   return (
      <div className="Chatbot">
         <div className='Guard'>
            <MainContainer>
               <ChatContainer>
                  <MessageList
                     style={{
                        paddingBottom: isTyping ? "40px" : "0px" 
                     }}
                     scrollBehavior="smooth"
                     typingIndicator={
                        isTyping ? <TypingIndicator style={{ backgroundColor: "transparent" }} 
                        content="Marcy is typing..." /> : null
                     }
                  >
                     {messages.map((message, i) => {
                        return (
                           <Message key={i} model={message}>
                              <Avatar src={message.avatar} />
                           </Message>
                        );
                     })}
                  </MessageList>
                  <MessageInput placeholder="Type message here" sendButton={true} onSend={handleSend} attachButton={false} />
               </ChatContainer>
            </MainContainer>
         </div>
      </div>
   );
}
export default Chatbot
