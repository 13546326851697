import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Alert } from 'react-bootstrap';
import './testnet.css';

const DemoSection = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [geoJson, setGeoJson] = useState('');
  const [isMinted, setIsMinted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
  const TEMPLATE_ID = process.env.REACT_APP_TESTNET_TEMPLATE_ID;

  // Simulated submit function for minting
  const handleMintRequest = (e) => {
    e.preventDefault();

    // Send the email using EmailJS
    emailjs
      .send(
        SERVICE_ID,
        TEMPLATE_ID,
        {
          geojson: geoJson,
          name: name,
          email: email,
        },
        PUBLIC_KEY
      )
      .then((response) => {
        console.log('Minting request email sent successfully!', response);
        setSuccessMessage('Your request for minting has been submitted successfully!');
        setIsMinted(true);
        setErrorMessage('');
        // Optionally clear form
        e.target.reset();
      })
      .catch((error) => {
        console.error('Minting request email could not be sent:', error);
        setErrorMessage('There was an error submitting your request. Please try again.');
        setSuccessMessage('');
        setIsMinted(false);
      });
  };

  return (
    <div className="demo-section">      
      {/* Section for Requesting Minting */}
      <div className="mint-section">
        <h3>Request the Minting of a Property</h3>
        <p>
          Please create a GeoJSON polygon using <a href="https://geojson.io/" target="_blank" rel="noopener noreferrer">geojson.io</a>. 
          <br></br>Once done, paste the GeoJSON data below and enter your name.
        </p>
        
        <form className="demoForm" onSubmit={handleMintRequest}>
          <div className="geo">
            <label>GeoJSON Polygon:</label>
            <input 
              type="text" 
              value={geoJson} 
              onChange={(e) => setGeoJson(e.target.value)} 
              placeholder="Paste your GeoJSON here"
              required
            />
          </div>
          
          <div className="nameLabel">
            <label>Your Name:</label>
            <input 
              type="text" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              placeholder="Enter your name"
              required
            />
          </div>

          <div className="mail">
            <label>Your Email (for notifications):</label>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="Enter your email" 
            />
          </div>
          
          <button type="submit">Submit Minting Request</button>
        </form>

        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        
      </div>

      {/* Section for Viewing Minted Properties */}
      <div className="map-section" style={{ marginTop: '40px' }}>
        <h3>View Minted Properties</h3>
        <p>Click below to view all the properties that have been minted.</p>
        <a href="https://swupelpms.com/maps/map.html" target="_blank" rel="noopener noreferrer">
          <button>View Map of Minted Properties</button>
        </a>
      </div>
    </div>
  );
};

export default DemoSection;
