import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import './BasicMap.css';
import leaflet from 'leaflet';

function BasicMap() {
  const [data, setData] = useState(null);
  const [useSatMap, setUseSatMap] = useState(false);

  useEffect(() => {
    // Fetch data from server using Axios
    async function fetchData() {
      const response = await axios.get('/property_balance');
      setData(response.data);
    }
    fetchData();
  }, []);

  // Handle click event on GeoJSON feature
  const handleClick = (event = {}, feature) => {
    const { owner, balance, sqm } = feature?.properties || {};
    leaflet.DomEvent.stopPropagation(event);
    feature.setStyle({ fillColor: 'rebeccaPurple' });
    feature.bindPopup(
      `<div>
        <p>Owner: ${owner}</p>
        <p>Balance: ${balance}</p>
        <p>Sqm: ${sqm}</p>
      </div>`
    ).openPopup();
  };

  return (
    <>
      {/* Add buttons for switching between OpenStreetMap and satellite tile layers */}
      <div className="map-buttons">
        <button onClick={() => setUseSatMap(true)}>Satellite Map</button>
        <button onClick={() => setUseSatMap(false)}>OpenStreetMap</button>
      </div>
      {/* Render the MapContainer component with the selected tile layer */}
      <MapContainer center={[35.99426572724096, 33.60767280689838]} zoom={3} minZoom={2} scrollWheelZoom={false} >
        {useSatMap ? (
          <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        ) : (
          <TileLayer url="http://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          attribution="Google Satellite" />
        )}
           {/* Render GeoJSON data if it is available */}
           {data &&
              data.property_balance.map((property) => (
                 <GeoJSON
                    key={property.id}
                    data={property.features[0].geometry}
                    className="polygon"
                    onEachFeature={handleClick}
                 />
              ))}
      </MapContainer>
    </>
  );
}

export default BasicMap;
