import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import YouTube from "react-youtube"; // Import the react-youtube library

const Demonstration = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
  });
  const slideIn = useAnimation();
  const fadeIn = useAnimation();

  useEffect(() => {
    if (inView) {
      slideIn.start({
        opacity: 1,
        x: 0,
        transition: {
          delay: 0,
          type: "spring",
          duration: 1.9,
          bounce: 0.3,
        },
      });
      fadeIn.start({
        opacity: 1,
        y: 0,
        transition: {
          delay: 0,
          type: "spring",
          duration: 3,
          bounce: 0.3,
        },
      });
    } else {
      slideIn.set({
        x: -300,
        opacity: 0,
      });

      fadeIn.set({
        y: -40,
        opacity: 0,
      });
      slideIn.start({
        opacity: 0,
        x: -300,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.3,
        },
      });
      fadeIn.start({
        opacity: 0,
        y: 60,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.3,
        },
      });
    }
  }, [inView]);

  // YouTube video options
  const youtubeOptions = {
    width: "100%",
    playerVars: {
      // You can add any YouTube player options here (e.g., autoplay, mute, etc.)
    },
  };

  return (
    <div ref={ref} className="home-demonstration justify-content-center align-content-center text-center">
      <motion.h1 animate={slideIn}>DEMONSTRATION OF SWUPEL PMS</motion.h1>
      {/* Replace 'VIDEO_ID' with the YouTube video ID you want to embed */}
      <YouTube videoId="r7y0fMRAx4c" opts={youtubeOptions} />
    </div>
  );
};

export default Demonstration;
