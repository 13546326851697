import React from 'react';
import "./button.css";

export default function Button(props) {

    const handleOnClick = props.function

    return (
        <button style={props.style} className={props.color != "green" ? "button-28" : "button-28-green" || props.color != "black" ? "button-28-green" : "button-28-green"} role="button" onClick={handleOnClick !== "" ? handleOnClick : undefined}>
            {props.text != null ? props.text : "Button"}
        </button>
    )
}
