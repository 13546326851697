import React from 'react';
import "./loading.css";


export default function Loading() {
    return (
        <div className="spin">
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}