import React from 'react'
import "./backDrop.css"

const BackDrop = ({children,open,onClose}) => {
  return (
    <div
      className={`backdrop ${open ? "open" : ""}`}
      onClick={onClose}
    >
      {children}
    </div>
  )
}

export default BackDrop