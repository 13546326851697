import React, { useState } from 'react';
import "./seedPhraseDialogBox.css";
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const SeedPhraseDialogBox = ({ open, onClose }) => {
  const location = useLocation();
  const [seedPhrase, setSeedPhrase] = useState([]);
  const [error, setError] = useState('');

  const fetchSeedPhrase = async (password, walletName) => {
    try {
      const response = await axios.post('/get.seed', {
        password,
        wallet_name: walletName
      });
      const { data } = response;
      if (data.seed) {
        setSeedPhrase(data.seed.split(' '));
        setError('');
      } else {
        setSeedPhrase([]);
        setError(data.error);
      }
    } catch (error) {
      setError('An error occurred while fetching the seed phrase.');
    }
  };

  const handleFetchSeedPhrase = async (e) => {
    e.stopPropagation();
    const password = prompt('Enter your password');
    if (password) {
      const walletName = localStorage.getItem("walletName");
      await fetchSeedPhrase(password, walletName);
    }
  };

  return (
    <div>
      {open && (
        <div className="dialog" onClick={(e)=>e.stopPropagation()}>
          <h1 className="dialog-header">Your Seed Phrase</h1>
          {seedPhrase.length > 0 ? (
            <div className="seed-phrase-grid">
              {seedPhrase.map((word, index) => (
          <div className="seed-phrase-word" key={index}>{word}</div>
        ))}
            </div>
          ) : (
            <p className="dialog-error">{error}</p>
          )}
          <button className="dialog-button" onClick={onClose}>
            Close
          </button>
          {!seedPhrase.length && !error && (
            <button className="dialog-button" onClick={(e)=>handleFetchSeedPhrase(e)}>
              Fetch Seed Phrase
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default SeedPhraseDialogBox;
