import React from "react";
import "./MemberCard.css";

const MemberCard = ({ name, about, email, role, countryCode, city }) => {
  return (
    <div class="team-card">
      <div className="inner-container">
        <h1>{name}</h1>
        <img
          src={`https://flagcdn.com/256x192/${countryCode}.png`}
          alt="Team Member 1"
        />
      </div>
      <h2>{role}</h2>
      <p className="about">{about}</p>
      <p className="email">{email}</p>
    </div>
  );
};

export default MemberCard;
