import React from "react";
import "./About.css";

// Image imports
import image_one from "../../assets/img/about/working_on_one.jpeg";
import image_two from "../../assets/img/about/working_on_two.png";
import image_three from "../../assets/img/about/accomplished_one.png";
import image_four from "../../assets/img/about/accomplished_two.jpeg";
import image_five from "../../assets/img/about/accomplished_three.png";
import image_six from "../../assets/img/about/accomplished_four.png";
import ImageWithOverlay from "./components/ImageWithOverlay";

const About = () => {
   return (
      <main className="main-container">
         <div>
            <h1 className="heading">Who are we?</h1>
            <p>
               We are a small, international group of computer science enthusiasts
               working on a decentralized solution to the many problems with
               centralized land ownership/management. These problems are corruption,
               land grabbing, environmental damage and many more, which do not only
               result in extra costs for any government and landowner but also
               threaten the existence of indigenous populations, farmers or other not
               very wealthy and influential populations. Believing that the current
               global systems of land management are fundamentally flawed we strive
               to change the world for the better.
            </p>
         </div>
         <div>
            <h1 className="heading">What are we working on ?</h1>
            <p>
               We are working on a digital land registry centered around a
               blockchain-based property registry. Why blockchain? Because current
               systems mostly suffer from a lack of transparency and rely too heavily
               on trust, we believe that land registries should be 100% transparent
               and not just rely on trust in the authorities and officials that run
               them. Decentralized storage also provides great benefits, considering
               overall safety and accessibility. Our vision is a land registry
               everyone can freely take a look into (while ensuring data authenticity
               via cryptographic proofs), a system that can detect illegal zoning
               violations such as deforestation or not permitted buildings, and we
               want to pave the way to a fair future, leaving land grabbing,
               corruption, and preventable environmental damage behind.
            </p>
            <div className="image-box">
               <ImageWithOverlay src={image_one} alt="Land zoning example" imageStyle={"image-style"}/>
               <ImageWithOverlay src={image_two} alt="User property detection example" imageStyle={"image-style"}/>
               <ImageWithOverlay src={image_four} alt="Simulation interface" imageStyle={"image-style2"}/>
            </div>
         </div>
         <div className="pain">
            <h1 className="heading">What have we already accomplished ?</h1>
            <p>
               Diving straight into this seemingly impossible challenge, we have
               already built a prototype for all of the essential parts of the
               system.
            </p>
            <p>
               <ul className="list">
                  <li>
                     Neuronal networks for detecting illegal buildings or deforestation
                  </li>
                  <li>Satellite APIs for retrieving images to classify</li>
                  <li>Blockchain forming the center of the whole project</li>
                  <li>Frontends/interfaces for all programs</li>
                  <li>Smart contract language (experimental)</li>
                  <li>Simulations to help make important design decisions</li>
               </ul>
            </p>
            <div className="image-box">
               <ImageWithOverlay src={image_three} alt="Computer vision for the use of land example" imageStyle={"image-style"}/>
               <ImageWithOverlay src={image_six} alt="Computer vision for the use of land example" imageStyle={"image-style"}/>
               <ImageWithOverlay src={image_five} alt="Simulation interface" imageStyle={"image-style2"}/>
            </div>
         </div>
         <div>
            <h1 className="heading">What are the benefits for adopters ?</h1>
            <p>
               Even users from less populated areas can manage all their rented or
               owned properties/lands without anything more than internet access.
               Benefiting from its decentralized structure, hacking attacks, natural
               disasters, or other threats have a much lower risk of impacting land
               registry performance or even destroying records. Automatic detection
               of zoning violations not only allows for the prosecution of owners of
               illegal buildings and structures but also helps in the fight against
               unregistered company actions such as illegal logging or mining.
               Allowing everyone to securely access and verify any land registry
               related information also helps with uncovering of corruption or crimes
               such as land grabbing or tax fraud. Management of the real estate
               sector is made easier, as property rights can be enforced much more
               directly due to having one standard for all real estate-related
               records. This also results in making the querying and analysis of
               prices, volume, volatility, and many more financial data points much
               easier.
            </p>
         </div>
      </main>
   );
};

export default About;
