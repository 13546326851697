import React, { useState, useEffect } from 'react';
import './createWallet.css';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button/Button';

export default function CreateWallet() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [walletName, setWalletName] = useState('');
  const [seedPhrase, setSeedPhrase] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleCreateWallet = () => {
    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }
  
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
  
    // Send a POST request to check if the wallet name is already in use
    fetch('http://127.0.0.1:5001/check_name', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ wallet_name: walletName })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error checking wallet name');
        }
      })
      .then(data => {
        if (data.used) {
          setError('Wallet name already in use');
        } else {
          // Send a POST request to create a new wallet
          fetch('http://127.0.0.1:5001/wallet.create', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password, walletName })
          })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Error creating wallet');
              }
            })
            .then(data => {
              // Save the seed phrase to state
              setSeedPhrase(data.seed_phrase);
            })
            .catch(error => setError(error.message));
        }
      })
      .catch(error => setError(error.message));
  };
  

  const toggleShowPassword = () => {
    setShowPass(prevState => !prevState);
  }

  useEffect(() => {
    if (seedPhrase) {
      navigate('/seedPhrase', { state: { seedPhrase } });
    }
  }, [navigate, seedPhrase]);

  return (
    <div className="create-wallet-container">
      <h1 className="create-wallet-title">Create a New Wallet</h1>
      <div className="create-wallet-form">
        {error && <div className="error-box">{error}</div>}
        <div className="create-wallet-form-field">
          <label htmlFor="walletName">Wallet Name:</label>
          <input type="text" id="walletName" name="walletName" autoComplete="off" value={walletName} onChange={event => setWalletName(event.target.value)} required />
        </div>
        <div className="create-wallet-form-field">
            <label htmlFor="password">Password:</label>
            <input type={showPass ? "text" : "password"} id="password" name="password" value={password} onChange={event => setPassword(event.target.value)} required />
        </div>
        <div className="create-wallet-form-field">
          <div className="pass">
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input type={showPass? "text" : "password"} id="confirmPassword" name="confirmPassword" value={confirmPassword} onChange={event => setConfirmPassword(event.target.value)} required />
          </div>
          <div className="show-pass">
            <button type="button" onClick={toggleShowPassword}>
              {showPass ? 'Hide' : 'Show'}
            </button>        
          </div>
      </div>
        <div className="create-wallet-buttons">
          <Button text="Create Wallet" color="green" function={handleCreateWallet} />
        </div>
      </div>
    </div>
  );
}
