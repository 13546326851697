import React, {useEffect}  from "react"

/** framer-motion **/
import {motion} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {useAnimation} from "framer-motion";


const Problems = () => {

    const {ref, inView} = useInView({
        threshold: 0.4
    });
    const slideIn = useAnimation();
    const fadeIn = useAnimation();

    useEffect(() => {
        if (inView) {
            slideIn.start({
                opacity: 1,
                x: 0,
                transition: {
                    delay: 0,
                    type: "spring",
                    duration: 1.9,
                    bounce: 0.3
                },
            });
            fadeIn.start({
                opacity: 1,
                y: 0,
                transition: {
                    delay: 0,
                    type: "spring",
                    duration: 3,
                    bounce: 0.3
                },
            })
        } else {
            slideIn.set({
                x: -300,
                opacity: 0,
            })

            fadeIn.set({
                y: -40,
                opacity: 0,
            })
            slideIn.start({
                opacity: 0,
                x: -300,
                transition: {
                    type: "spring",
                    duration: 1,
                    bounce: 0.3
                }
            }
            )
            fadeIn.start({
                opacity: 0,
                y: 60,
                transition: {
                    type: "spring",
                    duration: 1,
                    bounce: 0.3
                }
            })
        }
    }, [inView])

    return (
        <div ref={ref} className="home-problems d-flex flex-column align-items-start w-75">
            <motion.h1 animate={slideIn}>PROBLEMS TO BE SOLVED</motion.h1>
            <motion.div animate={fadeIn} >
                <p>Tradional land registries suffer from many issues:</p>
                <ul>
                <li>Zoning Fraud</li>
                <li>Lack of accessibility</li>
                <li>Lack of Transparency</li>
                <li>Lack of Speed</li>
                </ul>
            </motion.div>
        </div>
    )
}

export default Problems;
