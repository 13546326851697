import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Navbar_links } from "./NavbarList";
import pms_logo from "../../assets/img/logo.png";
import "./header.css";

const Header = () => {
  const [hiddenNavbar, setHiddenNavbar] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [check, setCheck] = useState(0);
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      className="navbar bg-transparent"
    >
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={pms_logo}
            width="60"
            height="60"
            className="logo_spin"
            alt="oops"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {Navbar_links.map((nav_links, nav_index) => {
              return (
                <Nav.Link
                  key={nav_index}
                  as={Link}
                  to={nav_links.url}
                  className={nav_links.cName}
                >
                  {nav_links.title}
                </Nav.Link>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
