import React, { useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyToClipboardButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
      const { clipboard } = navigator;
      clipboard.writeText(text);
      setCopied(true);
  };

  return (
    <span onClick={handleCopy}>
      {copied ? (
        <ContentCopyIcon sx={{color:'grey'}}/>
      ) : (
        <ContentCopyIcon color="primary" sx={{cursor:'pointer'}}/>
      )}
    </span>
  );
};

export default CopyToClipboardButton;