const teamMembers = [
  {
    name: "Anton Graller",
    about:
      "Teaching himself not only blockchain and AI technology but also programming solely to realize this project, Anton is dedicated to not only lead but also build on the forefront of this endeavor",
    email: "anton@swupelpms.com",
    role: "Founder",
    country_iso_code: "at",
    city: "Vienna",
  },
  {
    name: "Amlan Jyoti Sarmah",
    about:
      "As an initially self taught programmer Amlan (short AJ), currently pursuing a computer science degree, is excited to help build the next generation of the Swupel PMS blockchain",
    email: "amlan@swupelpms.com",
    role: "Blockchain Developer",
    country_iso_code: "in",
    city: "Nagaon Assam",
  },
  {
    name: "Mashrafi A. Farazi",
    about:
      "As one of the biggest AI enthusiasts the world has ever seen, Mashrafi oversees Marcy the chatbot and general frontend developments",
    email: "mashrafi@swupelpms.com",
    role: "Frontend Developer",
    country_iso_code: "bd",
    city: "Dhaka",
  },
  {
    name: "Yash Patil",
    about:
      "As a true programmer Yash is not only responsible for a lot of the frontends progress but will most likely also dive into the C++ development section of the project",
    email: "yash@swupelpms.com",
    role: "Frontend Developer",
    country_iso_code: "in",
    city: "Nashik",
  },
  {
    name: "Victor Salazar MSc",
    about:
      "Having a masters degree in in blockchain technologies Victors main motivation has always been to make the world a better place (orienting himself on the SDGs) using blokchain tech",
    email: "victor@swupelpms.com",
    role: "Scientific Research",
    country_iso_code: "mx",
    city: "Mexico City",
  },
  {
    name: "Daksh Bhatnagar",
    about:
      "As the man for everything AI, Daksh is working on building networks as accurate as to catch every last zoning fraudster on this beautiful planet",
    email: "daksh@swupelpms.com",
    role: "Artificial Intelligence",
    country_iso_code: "in",
    city: "New Delhi, Dehli",
  },
  {
    name: "Michael Adebayo",
    about:
      "Working on the core of the project, Michael is not afraid to tackle thousands of lines of code, document patches of unchartered code and to implement new ways of organising the biggest messes",
    email: "michael@swupelpms.com",
    role: "Backend Developer",
    country_iso_code: "ng",
    city: "Abuja",
  },
  {
    name: "Harsh Manek",
    about:
      "Utilizing his extensive experience in real world businesses Harsh helps the project improve the business and marketing site of things",
    email: "harsh.manek@swupelpms.com",
    role: "Project Manager",
    country_iso_code: "in",
    city: "Gujarat",
  },
  {
    name: "Lee Johnson",
    about:
      "Helping to build a professional but most importantly user friendly frontend experience, Lee is on the forefront of making Swupel PMS accessible to a broader scope of people",
    email: "lee@swupelpms.com",
    role: "Frontend Developer",
    country_iso_code: "za",
    city: "cape town",
  },
  {
    name: "Samuel Morton",
    about: "As a self-taught programmer, Samuel contributes to the frontend development of Swupel PMS, with a primary focus on enhancing the capabilities of the chatbot Marcy.",
    email: "sam@swupelpms.com",
    role: "Frontend Developer",
    country_iso_code: "us",
    city: "Oklahoma City",
  },
  {
  name: "Harsh Shaw",
  about:
    "Due to extensive experience in the tech/startup world Harsh is able to help Swupel PMS maneuver through the international startup scene",
  email: "harsh.shaw@swupelpms.com",
  role: "Business Advisor",
  country_iso_code: "in",
  city: "Hyderabad",
}
];

export default teamMembers;
