import React, { useState, useEffect } from "react";
import Button from "../../components/button/Button";
import Loading from "../../components/loading/Loading";
import BasicMap from "../../components/leaflet_map/BasicMap";
import SeedPhraseDialogBox from "../../components/dialogBox/SeedPhraseDialogBox";
import BackDrop from "../../components/backDrop/BackDrop";
import CopyToClipboardButton from "../../components/CopyToClipboardButton/CopyToClipboardButton";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CreateWallet from "./createWallet";
import "./createWallet.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./wallet.css";
import { Route } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Wallet() {
    const [wallet_data, setWallet_Data] = useState([{}]);
    const [key, setKey] = useState();
    const [coins, setCoins] = useState();
    const [Address, setAddress] = useState();
    const [code, setCode] = useState();
    const [loading, setLoading] = useState(false);
    const [publicKey, setPublicKey] = useState(
        "000102030405060708090A0B0C0D0E0F101112131415161718191A1B1C1D1E1F202122232425262728292A2B2C2D2E2F303132333435363738393A3B3C3D3E3F404142434445464748494A4B4C4D4E4F505152535455565758595A5B5C5D5E5F606162636465666768696A6B6C6D6E6F707172737475767778797A7B7C7D7E7F808182838485868788898A8B8C8D8E8F909192939495969798999A9B9C9D9E9FA0A1A2A3A4A5A6A7A8A9AAABACADAEAFB0B1B2B3B4B5B6B7B8B9BABBBCBDBEBFC0C1C2C3C4C5C6C7C8C9CACBCCCDCECFD0D1D2D3D4D5D6D7D8D9DADBDCDDDE"
    );

    const fetchPublicKey = () => {
        const opts = {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
        };
        fetch("/wallet.load", opts)
            .then((resp) => resp.json())
            .then((data) => {
                setPublicKey(data.public_key);
            });
    };

    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem("isLoggedIn") === "true" ? true : false
    );
    const [openSeedPhraseDialog, setOpenSeedPhraseDialog] = useState(false);

    const [walletName, setWalletName] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem("isLoggedIn", isLoggedIn);
    }, [isLoggedIn]);

    const handleLogout = () => {
        localStorage.setItem("isLoggedIn", false);
        navigate("/");
    };

    const getWalletName = () => {
        setWalletName(localStorage.getItem("walletName"));
    };

    useEffect(() => {
        getWalletName();
    }, []);

    const load_wallet = () => {
        setLoading(true);
        const opts = {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
        };

        fetch("/wallet.load", opts)
            .then((resp) => resp.json())
            .then((data) => {
                setWallet_Data(data);
                setLoading(false);
            });
    };

    const Create_Wallet = () => {
        const opts = {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
        };
        fetch("/wallet.create", opts)
            .then((resp) => resp.json())
            .then((data) => {
                setWallet_Data(data);
                setLoading(false);
            });
    };

    const create_keys = () => {
        setLoading(true);
        const opts = {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
        };

        fetch("/wallet.create", opts)
            .then((resp) => resp.json())
            .then((data) => {
                setWallet_Data(data);
                setLoading(false);
            });
    };

    const Convert_Address = () => {
        const opts = {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                geojson: {
                    id: 1571,
                    latlngs: [
                        {
                            lat: 53.557389819328705,
                            lng: 9.813537597656252,
                        },
                        {
                            lat: 53.60305655845247,
                            lng: 9.8876953125,
                        },
                        {
                            lat: 53.60957635153803,
                            lng: 10.031890869140627,
                        },
                        {
                            lat: 53.56146921262711,
                            lng: 10.174713134765627,
                        },
                        {
                            lat: 53.4233675600433,
                            lng: 10.129394531250002,
                        },
                        {
                            lat: 53.474901820717506,
                            lng: 9.783325195312502,
                        },
                    ],
                },
            }),
        };
        fetch("/convert_to_address", opts).then((data) => {
            setWallet_Data(data);
            setLoading(false);
        });
    };

    const abstractPublicKey = (str, maxLen) => {
        if (str.length <= maxLen) {
            return str;
        } else {
            return str.substring(0, maxLen) + "...";
        }
    };

    if (!isLoggedIn) {
        return (
            <div className="logInMessage">
                <h2>You are not logged in.</h2>
                <p>Please log in to access your wallet.</p>
                <Row xs="auto" className="wallet-nav m-5 me-2">
                    <Col className="btn">
                        <Link to="/wallet/create">
                            <Button text="Create Wallet"></Button>
                        </Link>
                    </Col>
                    <Col className="btn">
                        <Link to="/wallet/load">
                            <Button text="Load Wallet" function={load_wallet}>
                                {" "}
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <section className="wallet-container">
            {loading ? <Loading /> : ""}
            <h1 className="wallet-title text-center pt-5">Manage your Wallet</h1>
            <h1 className="walletName">{walletName}</h1>
            <div className="wallet-key-container">
                <div className="wallet-public-key-container">
                    <p>
                        Public Key{" "}
                        <span
                            className="inline-eye"
                            onClick={() => setOpenSeedPhraseDialog(true)}
                        >
                            <VisibilityIcon />
                        </span>
                    </p>
                    <p>
                        {abstractPublicKey(publicKey, 20)}{" "}
                        <CopyToClipboardButton text={publicKey}></CopyToClipboardButton>
                    </p>
                </div>
                <span
                    className="flex-eye"
                    onClick={() => setOpenSeedPhraseDialog(true)}
                >
                    <VisibilityIcon />
                </span>
                {openSeedPhraseDialog && (
                    <BackDrop
                        open={openSeedPhraseDialog}
                        onClose={() => setOpenSeedPhraseDialog(false)}
                    >
                        <SeedPhraseDialogBox
                            open={openSeedPhraseDialog}
                            onClose={() => setOpenSeedPhraseDialog(false)}
                        />
                    </BackDrop>
                )}
            </div>
            {/*
            
            */}
            <hr size="20" />
            {typeof wallet_data === "undefined" ? (
                <p>loading</p>
            ) : (
                <table className="table table-dark mb-5 mt-5">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Area</th>
                            <th scope="col">City</th>
                            <th scope="col">Country</th>
                            <th scope="col">Funds</th>
                            <th scope="col">PLZ</th>
                            <th scope="col">Street</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>{wallet_data.area}</td>
                            <td>{wallet_data.city}</td>
                            <td>{wallet_data.country}</td>
                            <td>{wallet_data.funds}</td>
                            <td>{wallet_data.plz}</td>
                            <td>{wallet_data.street}</td>
                        </tr>
                    </tbody>
                </table>
            )}
            <hr size="20" className="mb-5" />
            <Row className="wallet-map">
                <BasicMap />
            </Row>
            <div
                style={{
                    padding: "15px 50px",
                    fontWeight: "bold",
                    fontSize: "24px",
                    backgroundColor: "rgba(112, 128, 144,0.4)",
                    backdropFilter: "blur(2px)",
                    borderRadius: "10px",
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    display: "inline-block",
                }}
            >
                Send
            </div>
            <div className="inputFields-container">
                <label className="wallet-key">
                    <p>Recipient Key:</p>
                </label>
                <input
                    className="wallet-input"
                    style={{ borderTop: "none", borderLeft: "none", borderRight: "none" }}
                    value={key}
                    onChange={(e) => setKey(e.target.value)}
                    type="text"
                    placeholder="Enter the key ..."
                    name="key"
                    id="enter-key"
                    required
                    autoComplete="off"
                ></input>
                <label className="wallet-key">
                    <p>Properties Address:</p>
                </label>
                <input
                    className="wallet-input"
                    style={{ borderTop: "none", borderLeft: "none", borderRight: "none" }}
                    value={Address}
                    onChange={(e) => setAddress(e.target.value)}
                    type="text"
                    placeholder="Enter your Address ..."
                    name="Address"
                    id="enter-Address"
                    required
                    autoComplete="off"
                ></input>
                <label className="wallet-key">
                    <p>Enter smart contract code here:</p>
                </label>
                <input
                    className="wallet-input"
                    style={{ borderTop: "none", borderLeft: "none", borderRight: "none" }}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    type="text"
                    placeholder="Enter your contract Code ..."
                    name="contract-code"
                    id="enter-code"
                    required
                    autoComplete="off"
                ></input>
                <label className="wallet-key">
                    <p>Amount of Coins</p>
                </label>
                <input
                    className="wallet-input"
                    style={{ borderTop: "none", borderLeft: "none", borderRight: "none" }}
                    value={coins}
                    onChange={(e) => setCoins(e.target.value)}
                    type="text"
                    placeholder="Enter your Coins ..."
                    name="coins"
                    id="enter-Coins"
                    required
                    autoComplete="off"
                ></input>
                <div style={{ maxWidth: "50%", margin: "auto" }}>
                    <Button
                        style={{ fontWeight: "900", fontSize: "24px" }}
                        text="Send"
                        color="green"
                    >
                        {" "}
                    </Button>
                </div>
            </div>
            <hr size="20" className="mb-5" />
            <Row xs="auto" className="wallet-nav m-2 me-2 pb-5 mb-5">
                <Col></Col>
                <Col>
                    <Button text="Mine Coins"> </Button>
                </Col>
                <Col>
                    <Button text="Resolve Conflicts"> </Button>
                </Col>
            </Row>
            <div>
                <Button text="Remove your wallet" function={handleLogout}>
                    {" "}
                </Button>
            </div>
        </section>
    );
}
