// SeedPhrase.js

import React, { useState, } from 'react';
import { useLocation } from 'react-router-dom';
import './seedPhrase.css';
import { useNavigate } from 'react-router-dom';

export default function SeedPhrase() {
  const location = useLocation();
  const seedPhrase = location.state.seedPhrase;
  const words = seedPhrase.split(' ');
  const [showSeedPhrase, setShowSeedPhrase] = useState(false);
  const navigate = useNavigate();

  const handleShowSeedPhrase = () => {
    setShowSeedPhrase(true);
  }

  const handleConfirmSeedPhrase = () => {
    // TODO: Add code to confirm seed phrase
    navigate('/wallet');
  }

  return (
    <div className="seed-phrase-container">
      <h1>Seed Phrase</h1>
      <p>Please write down your seed phrase and keep it safe:</p>
      <div className="seed-phrase-grid">
        {showSeedPhrase && words.map((word, index) => (
          <div className="seed-phrase-word" key={index}>{word}</div>
        ))}
      </div>
      <div className="seed-phrase-btn-container">
        <button className="seed-phrase-btn" onClick={handleShowSeedPhrase}>Display Seed Phrase</button>
        <button className="seed-phrase-btn" onClick={handleConfirmSeedPhrase}>Confirm Seed Phrase</button>
      </div>
    </div>
  );
}
