import React, {useEffect} from "react"

import pms_logo from "../../../assets/img/logo.png";

import {motion} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {useAnimation} from "framer-motion";

export default function Title() {

    const {ref, inView} = useInView({});
    const slideIn = useAnimation();
    const fadeIn = useAnimation();

    useEffect(() => {
        if (inView) {
            slideIn.set({
                x: -300,
                opacity: 0,
            })
            slideIn.start({
                opacity: 1,
                x: 0,
                transition: {
                    delay: .5,
                    type: "spring",
                    duration: 1.9,
                    bounce: 0.3
                },
            });

            fadeIn.set({
                y: -40,
                opacity: 0,
            })
            fadeIn.start({
                opacity: 1,
                y: 0,
                transition: {
                    delay: 0.2,
                    type: "spring",
                    duration: 1.9,
                    bounce: 0.3
                },
            })
        } else {
            slideIn.start({
                    opacity: 0,
                    x: -300,
                    transition: {
                        type: "spring",
                        duration: 1.9,
                        bounce: 0.3
                    }
                }
            )
            fadeIn.start({
                opacity: 0,
                y: 60,
                transition: {
                    type: "spring",
                    duration: 1.9,
                    bounce: 0.3
                }
            })
        }
    }, [inView])


    return (
        <div ref={ref} className="home-title d-flex justify-content-center align-content-center mt-200">
            <motion.img animate={slideIn} src={pms_logo} height="150px" width="150px" className="logo_spin"/>
            <motion.div animate={fadeIn} className="p-lg-5">
                <div className="home-title-bg-cl">
                    <h1 className='home-title-text'>SWUPEL PMS</h1>
                    <p className="d-md-block d-lg-none">The Blockchain Land Registry</p>
                    <p className="d-none d-lg-block">The Blockchain Land Registry</p>
                </div>
            </motion.div>
        </div>
    )
}
